//引入vue-router路由插件
import VueRouter from "vue-router";
//引入Vue
import Vue from "vue";
Vue.use(VueRouter);

//引入路由组件
import Home from '../view/home/index.vue';
import PrivacyPolicy from "../view/privacypolicy/index.vue"
// import ResponsibleGameplayPolicy from "../view/responsiblegameplaypolicy/index.vue"
// import SweepsRules from "../view/sweepsrules/index.vue"
// import GameStatement from "../view/gamestatement/index.vue"
import TermsOfService from "../view/termsandconditions/index.vue"


const router = new VueRouter({
    mode: 'history',
    //配置路由
    routes:[
        {
            name:'home',
            path: '/home',
            component: Home,
        },
        {
            name:'de_confidentialite',
            path: '/de_confidentialite.html',
            component: PrivacyPolicy,
        },
        {
            name:'termes',
            path: '/termes.html',
            component: TermsOfService,
        },

        // {
        //     name:'devashome',
        //     path: '/devas/home',
        //     component: Home,
        // },
        // {
        //     name:'devasde_confidentialite',
        //     path: '/devas/de_confidentialite.html',
        //     component: PrivacyPolicy,
        // },{
        //     name:'devastermes',
        //     path: '/devas/termes.html',
        //     component: TermsOfService,
        // },{
        //     name:'devasrgp',
        //     path: '/devas/rgp.html',
        //     component: ResponsibleGameplayPolicy,
        // },{
        //     name:'devasgamestatement',
        //     path: '/devas/gamestatement.html',
        //     component: GameStatement,
        // },{
        //     name:'sweepsru',
        //     path: '/devas/sweepsru.html',
        //     component: SweepsRules,
        // },
        // {
        //     path: '/devas',
        //     redirect: '/devas/home',
        // },
        {
            path: '/*',
            redirect: '/home',
        }
    ]
  })


export default router
