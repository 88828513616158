<template>
  <div class="box">
    <img class="box_logo" src="../../assets/image/Logo.png" alt="">
    <div class="container">
      <h1>PRIVACYPOLICY</h1>
      <p class="notice">This privacy policy (“Policy”) informs you of your choices and our practices in relation to your Information (as defined below).In this Policy,"we”or "us" refers to CYBER SPHERE SARL.We choose why we need to use your Information and how it is used, which means we are considered the data controller in certain countries. For the purpose of this Policy, “Information” means any information relating to an identified or identifiable individual. When you use the Website and Services, you accept our rules and policies which set out how we handle your Information, and you understand we collect, process, use and store your Information as described in this Policy. If you do not agree with this Policy, you must not play any of the Games. If you change your mind in the future, you must stop playing the Games and you may exercise your rights in relation to your Information as set out in this Policy.</p>
      <ul class="ul_1">
        <li>
          <p><strong>PERSONALINFORMATIONWECOLLECT</strong></p>
          <p>
            We will process and use the following Information about you:
            <ul class="ul_2">
              <li>
                Information you provide to us:
                <ul class="ul_3">
                  <li><strong>Contact information,</strong> such as your name, username, phone number and email address.</li>
                  <li><strong>Payment information,</strong> such as the form of payment you use, payment method details (such as credit or debit card number).</li>
                  <li><strong>Any photos that you upload to your profile.</strong> </li>
                  <li><strong>Transaction and purchase information, </strong>such as any cash top-ups that you make and the frequency with which you top-up your account in the Game.</li>
                </ul>
              </li>
              <li>
                Information we automatically collect or is generated about you when you play the Games
                <ul class="ul_3">
                  <li><strong>Identifiers: </strong>your IP address, advertising ID, Adjust ID, HTTP header, MAC address, device ID, third-party app IDs (apptoken), country and city level location data and device information (such as model, brand, language, and operating system).</li>
                  <li><strong>Cookies:</strong>we use cookies and other similar technologies (e.g., web beacons, log files, scripts and eTags) (“Cookies”) to enhance your experience when using the Games. Cookies are small files which, when placed on your device, enable us to provide certain features and functionality. You have the option to permit installation of such Cookies or subsequently disable them. You may accept all Cookies or instruct the device or web browser to provide notice at the time of installation of Cookies or refuse to accept all Cookies by adjusting the relevant cookie retention function in your device. However, in the event of your refusal to install Cookies, the Game may be unable to operate as designed.</li>
                  <li><strong>Information regarding your use of the Games: </strong> game ID,date and time stamps of game events, game time, achieved scores, interactions with our teams, advertising and promotions engagement, transaction records, evidence of you using cheating software or using modified versions of the Games.</li>
                </ul>
              </li>
              <li>
                1.3 Information received from third parties
                <ul class="ul_3">
                  <li><strong>Payment providers: </strong>When you make payments in our Games, we receive the following Information from our payment providers about your transactions: transaction ID, country, payment provider used, purchased item, currency, value, and payment timestamp.</li>
                  <li><strong>Advertising partners: </strong>we receive Information from third-party advertising providers, including user ID and Information on your interactions with advertising shown in on our Mobile Games.</li>
                  <li><strong>Mobile measurement partners: </strong>we receive information from third parties to allow us to track performance of our marketing campaigns and to detect fraud in such campaigns. This includes advertising ID, IP address, location, and transaction information.</li>
                  <li><strong>Communication providers: </strong>in some Games you may have functionalities like chat, audio, or video calls. When these are provided by third parties, we will receive Information about you from them, including third-party IDs, usernames, or content in some cases.</li>
                </ul>
              </li>
            </ul>
          </p>
          <span style="font-weight: bolder;">CHILDREN</span>
        </li>
        <li>
          <p><strong>HOW WE USE YOUR PERSONAL INFORMATION</strong></p>
          <ul class="ul_2">
            <ul class="ul_3">
              <li><strong>Provide you with the Games. </strong>We will use your Information to perform our contractual obligation towards you to allow you to create an account, play the Games (including as a guest), remember your progress, allow you to play Games with your friends and communicate with them via in-Game chat, audio, or video calls (if applicable).The Information we process when doing so includes your registration Information, Information you provide to us when playing the Games, identifiers, Information you generate and display when playing the Games, and Information regarding your use of the Games.</li>
              <li><strong>Improve and monitor Game use. </strong>It is in our legitimate interests to improve our Games for our customers. When doing so, we may automatically collect information or generate Information about you when you play the Games, as well as Information about your device such as battery, Wi-Fi strength, device manufacturer, model and operating system.</li>
              <li><strong>Provide you with support and to respond to your requests or complaints. </strong>If you reach out to us for support, we will use your Information to create a support account for you, respond and resolve your queries and complaints, facilitate support. When doing so, we perform our contractual obligation towards you. The Information we process when doing so includes your registration Information, your identifiers, information about your device such as your Wi-Fi strength and the amount of free space on your device, and any other Information about you collected via our customer support channels.</li>
              <li><strong>Conduct analytics. </strong>It is in our legitimate interests to analyse the use of and inform, our Games, newsletters and advertising campaigns, and any other interaction or interest in the same. When doing so we will process Information we automatically collect or is generated about you when you play the Games or interact with our newsletters and advertising campaigns to (a) create anonymised and aggregated data; (b) create segments of users who show particular characteristics or interests; and (c) conduct predictive analytics about your interests.</li>
              <li><strong>Send you updates about in-Game offers. </strong>We will show you promotional offers and information about virtual items that can be purchased in-game, which will be tailored to you    based on gameplay, geography, device data and in-game interactions. We do this based on our legitimate interest.</li>
              <li><strong>Prevent fraud, defend CYBER SPHERE SARL against legal claims or disputes, enforce our   terms and to comply with our legal obligations. </strong>It is in our legitimate interests to protect our   users against online abuse and other anti-social or illegal activity (e.g., when moderating conversation in our Games). Furthermore, it is also in our legitimate interest to protect our interests by (1) monitoring Game use to detect fraud or any other user behaviour which prejudices the integrity of our Games, (2) taking steps to remedy fraud and concerning behaviour, (3) defending ourselves against legal claims or disputes, and (4) enforcing our terms and policies. When doing so, we will process the Information relevant in such a case, including Information you provide us, Information we automatically collect about you, and Information which is provided to us by third parties.</li>
              <li><strong>Conduct surveys. </strong>From time to time, we may ask you to participate in surveys we conduct which are in our legitimate interest because they help us understand our userbase and improve   the Games. If you choose to participate, we process your registration Information and any other  Information collected through the survey questions.</li>
            </ul>
          </ul>
        </li>
        <li>
          <p><strong>WHO WE SHARE YOUR PERSONAL INFORMATION WITH</strong></p>
          <p><strong>We share your Information with selected third parties, including:</strong></p>
          <ul class="ul_2">
            <ul class="ul_3">
              <li><strong>Other players and users </strong>will see your profile Information and in-game activities, as well as any other Information you choose to share with them, such as chat data;</li>
              <li><strong>Third party developers: </strong>When we publish third party games, we share your Information with the developer of the relevant Game in order to provide you with the Game.</li>
              <li><strong>Companies who belong to the same group as CYBER SPHERE SARL.</strong>We share your Information with different entities which are part of our group of companies.</li>
              <li><strong>Law enforcement agencies, public authorities or other judicial bodies and organisations.</strong>We disclose Information if we are legally required to do so, or if we have a good faith belief that such use is reasonably necessary to comply with a legal obligation, process or request; enforce our terms of service and other agreements, policies, and standards, including investigation of any potential violation thereof; detect, prevent or otherwise address security, fraud or technical issues; or protect the rights, property or safety of us, our users, a third party or the public as required or permitted by law (including exchanging information with other companies and organisations for the purposes of fraud protection).</li>
              <li><strong>Change of corporate ownership. </strong>If we are involved in a merger, acquisition, bankruptcy,  reorganisation, partnership, asset sale or other transaction, we may disclose your Information as part of that transaction.</li>
            </ul>
          </ul>
          <p style="font-style: italic;"><strong>THIRD PARTY PRIVACY PRACTICES</strong></p>
          <p style="font-style: italic;" class="p2">If you access any Games through a third-party platform(“Third-Party Services”), you must understand that those Third-Party Services may collect other Information about you (including Information you share with them directly or about your use of the Games) in accordance with their own terms and privacy policies. The privacy practices described in this Policy do not apply to Third Party Services. Any links in the Games to Third Party Services do not imply that we endorse or have reviewed the Third-Party Services.</p>
          <p style="font-style: italic;"><strong>SECURITY</strong></p>
          <p style="font-style: italic;" class="p2">Although we have in place security measures to maintain the privacy and integrity of your Information, unfortunately, the transmission of Information via the internet is not completely secure. We may also take extra steps to protect your Information and minimise the Information we process. For example, when you provide us with your password as part of the registration process, we apply a one-way hash.</p>
        </li>
        <li>
          <p><strong>HOW LONG WE STORE YOUR INFORMATION</strong></p>
          <p class="p2">Your Information is kept for as long as you are registered or using our games, and then for up to three years from the date you stop interacting with the games. When deleting Information, we will take measures to make the Information irrecoverable or irreproducible, and electronic files which contain Information will be deleted permanently.</p>
        </li>
        <li>
          <p><strong>CHANGES</strong></p>
          <p class="p2">Any updates or changes to this Policy will be published here. If there are any significant changes, we will notify you through the website and in-Game.</p>
        </li>
        <li>
          <p><strong>CONTACT US</strong></p>
          <p class="p2">To contact us with your questions or comments regarding this Privacy Policy or data collection, please email us at service@cyberspheresarl.com.We will endeavour to deal with your complaint as soon as possible.</p>
        </li>   
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RESPONSIBLEGAMEPLAYPOLICY'
}
</script>

<style lang="scss" scoped>
.box {
  background: #fff;
  width: 100%;
  padding-bottom: .4167rem;
  h1 {
    text-align: center;
    margin: .4167rem;
    color: #2fccbf;
  }
}
.p2{
  font-size: 14px;
}

.container {
  width: 6.7708rem;
  margin: 0 auto;
  .notice{
    font-size: 14px;
    line-height: 30px;
    width: 100%;
    display: block;
    word-wrap:break-word; 
    margin: 0 auto .2604rem;
  }
  span{
    font-size: 14px;
    line-height: .1563rem;
  }
  .tit{
    display: block;
    margin: .1042rem 0;
  }
}

.ul_1 {
  margin-top: .2604rem;
  list-style-type: decimal;
  margin-left: -1em;
  line-height: .1823rem;
  font-size: 20px;
  .ul_2 {
    font-size: 14px;
    list-style-type: lower-alpha;
    text-align: left;
    line-height: .1302rem;
    padding-left: .026rem;
    margin: .0521rem 0;
    .ul_3{
      font-size: 14px;
      list-style-type: disc;
      text-align: left;
      line-height: .1302rem;
      padding-left: .0521rem;
      margin: .0521rem 0;
    }
  }
}
.box_logo{
  width: 200px;
  height: auto;
  position: relative;
  left: 2.0313rem;
  top: .5729rem;
}
@media (max-width: 1000px) {
  .box_logo{
    width: 80px;
    height: auto;
    position: relative;
    left: .1042rem;
    top: 30px;
  }
  .container{
    h1{
      font-size: 24px;
    }
    .tit{
      font-size: 0.01em;
      line-height: 30px;
    }
}
  .ul_1 {
    font-size: 10px;
    strong{
      line-height:20px;
    }
    p{
      margin: 50px 0 0;
    }
    .ul_2{
      line-height: 30px;
      padding-left: .0521rem;
      .ul_3{
        line-height: 30px;
        padding-left: .0521rem;
      }
    }
  }
}

</style>